<template>
  <menu-main> </menu-main>
  <div id="tasks">
    <header-main> </header-main>
    <div id="main_body" class="cuerpo_principal">
      <div class="mosaico_cam">
        <div class="main_content">
          <div class="grid_busc">
            <form class="buscador">
              <input
                id="search"
                type="search"
                v-model="search"
                :placeholder="$t('common.search')"
              />
              <!--<button><i class="fa fa-search" aria-hidden="true"></i></button>-->
              <span class="material-icons refresh-button"
                @click="refresh">refresh</span>
            </form>
            
          </div>
          <p v-if="this.$root.dataManager.tasksLoaded && !this.$root.dataManager.tasks.length" class="no_data_msg">
            {{ $t('tasks.no_tasks') }}
          </p>
          <div class="grid_prog">
						<task-item
							v-for="(task, index) in filterTasks"
							:key="task.id"
							:id="task.id"
							:index="index"
							:name="task.name"
							:type="task.type"
							:initialDisabled="task.disabled"
							@delete-event="showDeleteConfirmation"
							@edit-event="editTask"
							>
						</task-item>
          </div>
        </div>
        <button
            v-show="editTasksPriv"
            @click="addTask"
            class="agregar_opcion"
          >
          <span class="material-icons">add</span>
          </button>
      </div>
    </div>


  </div>
  <menu-footer active-state="tasks"></menu-footer>
</template>

<script>
import MenuMain from "../Menus/MenuMain.vue";
import MenuFooter from "../Menus/MenuFooter.vue";
import HeaderMain from "../HeaderMain.vue";
import TaskItem from "./TaskItem.vue";
//const axios = require("axios").default;
//axios.defaults.withCredentials = false;
const Swal = require("sweetalert2");
export default {
  name: "TasksMain",
  components: {
    MenuMain,
    MenuFooter,
    HeaderMain,
    TaskItem,
  },
  data() {
    return {
      tasks: [],
      search: "",
      error: false,
      //loaded: false,
      editTasksPriv: false,
    };
  },
  computed: {
    filterTasks() {
      return this.search
        ? this.$root.dataManager.tasks.filter(task =>
            task.name
              .toLowerCase()
              .includes(this.search.toLowerCase())
          )
        : this.$root.dataManager.tasks;
    },
  },
  methods: {
    async refresh() {
      console.log("refresh");
      this.$root.dataManager.loadTasks(true);
    },
    showDeleteConfirmation(id, index) {
      console.log("showDeleteConfirmation:", id, index);
      var taskName = ""
      this.$root.dataManager.tasks.forEach((task) => {
          if (id == task.id)
                taskName=task.name
      });
      Swal.fire({
        /*title: this.recordings[index].camName,*/
        text: this.$t('tasks.task_del_confirmation',{taskname: taskName}),
        icon: "question",
        showCancelButton: true,
        confirmButtonText: this.$t('common.ok'),
        cancelButtonText: this.$t('common.cancel'),
        //confirmButtonColor: getComputedStyle(document.documentElement).getPropertyValue('--main-accent-color') /*"#61aaea"*/,
        //cancelButtonColor: getComputedStyle(document.documentElement).getPropertyValue('--main-inactive-color') /*"#c0c0c0"*/,
        position: "center",
      }).then((result) => {
        if (result.isConfirmed) {
          console.log("Delete:", id, index);
          this.deleteTask(id);
        }
      });
    },
    addTask() {
      console.log("addTask function");
      this.$router.push({ name: "TaskAdd" });
    },

    async deleteTask(id) {
      try {
        await this.$root.dataManager.deleteTask(id);
      }
      catch (error) {
        console.log("task delete ERROR", error);
      }
    },
    
    async editTask(id, index) {
      console.log("editTask", id, index);
      this.$router.push({ name: "TaskEdit", params: { editId: id } });
    },

  },

  mounted() {
    document.documentElement.style.scrollBehavior = 'auto';
    console.log("TasksMain");
    document.title = this.$t('common.tasks');
    //const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (!this.$root.sessionManager.isLoggedIn()) {
      console.log("router to signin");
      this.$router.push({ name: "SignIn" });
    }
    console.log("TasksMain, sessionid:", this.$root.sessionManager.sessionId);
    const userPriv = this.$root.sessionManager.privileges;
    console.log("UsersMain, userPriv:", userPriv);
    this.editTasksPriv = userPriv['editTasks'];
    //this.getTasks();
    //this.loaded=true;
    //console.log(this.$root.dataManager.tasks);
  },
  unmounted() {
    var oldMenu=document.querySelector('.dropdown-menu.show');
		if (oldMenu!=null) oldMenu.classList.remove("show");
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>