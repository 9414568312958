<template>
  <menu-main></menu-main>
  <div id="userAdd">
    <header-main> </header-main>
    <div class="cuerpo_principal">
      <div id="confUser" class="container">
        <div class="row">
          <div class="col-12 offset-0 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-md-8 offset-md-2 col-lg-6 offset-lg-3 visor_box">
            <div class="alert alert-custom" id="msg-alert">
              <p>{{ errorMessage }}</p>
            </div>
            <ul class="nav nav-tabs nav-fill" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="informacion-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#informacion"
                  type="button"
                  role="tab"
                  aria-controls="informacion"
                  aria-selected="true"
                >
                  {{ $t('common.information') }}
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="privileges-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#privileges"
                  type="button"
                  role="tab"
                  aria-controls="privileges"
                  aria-selected="false"
                >
                {{ $t('users.privileges') }}
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="cameras-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#cameras"
                  type="button"
                  role="tab"
                  aria-controls="cameras"
                  aria-selected="false"
                >
                {{ $t('common.cameras') }}
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="groups-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#groups"
                  type="button"
                  role="tab"
                  aria-controls="groups"
                  aria-selected="false"
                >
                {{ $t('common.groups') }}
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="labels-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#labels"
                  type="button"
                  role="tab"
                  aria-controls="labels"
                  aria-selected="false"
                >
                {{ $t('common.labels') }}
                </button>
              </li>
              
            </ul>
						<button
              @click="closeWindow"
              type="button"
              class="close_props material-icons"
              style="float: right"
              aria-label="Close"
            >close</button>
            <div class="tab-content" id="npContenido">
              <div
                class="tab-pane fade show active"
                id="informacion"
                role="tabpanel"
                aria-labelledby="informacion-tab"
              >
                <div class="conf_box">
                  <div class="nom_input">
                    <p>{{ $t('users.first_name') }}</p>
                    <input class="text_input"
                      v-model="name"
                      id="user_name"
                      type="text"
                      :placeholder="$t('users.user_first_name')"
                    />
                  </div>
                  <div class="nom_input">
                    <p>{{ $t('users.surname') }}</p>
                    <input class="text_input"
                      v-model="surname"
                      id="user_surname"
                      type="text"
                      :placeholder="$t('users.user_surname')"
                    />
                  </div>
                  <div class="nom_input">
                    <p>{{ $t('users.email') }}</p>
                    <input class="text_input"
                      v-model="email"
                      id="user_email"
                      type="email"
                      :placeholder="$t('users.user_email')"
                    />
                  </div>
                  <div v-if="!this.autouserpassword" class="nom_input">
                    <p>{{ $t('users.password') }}</p>
                    <input class="text_input"
                      :type="passwordFieldType"
                      v-model="password"
                      :placeholder="$t('users.user_password')"
                    />
                    <i
                      :class="{
                        'fa fa-eye': !this.passwordVisibility,
                        'fa fa-eye-slash': this.passwordVisibility,
                      }"
                      style="margin-left: -30px; cursor: pointer"
                      @click="switchPasswordVisibility()"
                    ></i>
                  </div>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="privileges"
                role="tabpanel"
                aria-labelledby="privileges-tab"
              >
                <div class="sw_prog">
                  <div class="sw_contenedor">
                    <p>{{ $t('privileges.view_cameras') }}</p>
                    <div class="sw_cam">
                      <input
                        type="checkbox"
                        id="swViewCamera"
                        v-model="viewCam"
                        class="switch_input"
                        disabled
                      />
                      <label for="swViewCamera" class="switch_label"></label>
                    </div>
                  </div>
                  <div class="sw_contenedor" v-if="ptzClientPriv">
                    <p>{{ $t('privileges.ptz') }}</p>
                    <div class="sw_cam">
                      <input
                        type="checkbox"
                        id="swPTZ"
                        v-model="ptz"
                        class="switch_input"
                      />
                      <label for="swPTZ" class="switch_label"></label>
                    </div>
                  </div>
                  <div class="sw_contenedor" v-if="onDemandRecClientPriv">
                    <p>{{ $t('privileges.ondemand_recording') }}</p>
                    <div class="sw_cam">
                      <input
                        type="checkbox"
                        id="swOndemandRec"
                        v-model="demandRec"
                        class="switch_input"
                      />
                      <label for="swOndemandRec" class="switch_label"></label>
                    </div>
                  </div>
                  <div class="sw_contenedor" v-if="showRecordingsClientPriv">
                    <p>{{ $t('privileges.view_recordings') }}</p>
                    <div class="sw_cam">
                      <input
                        type="checkbox"
                        id="swViewRec"
                        v-model="viewRec"
                        class="switch_input"
                      />
                      <label for="swViewRec" class="switch_label"></label>
                    </div>
                  </div>
                  <div class="sw_contenedor" v-if="exportRecordingsClientPriv">
                    <p>{{ $t('privileges.export_recordings') }}</p>
                    <div class="sw_cam">
                      <input
                        type="checkbox"
                        id="swExportRec"
                        v-model="exportRec"
                        class="switch_input"
                      />
                      <label for="swExportRec" class="switch_label"></label>
                    </div>
                  </div>
                  <div class="sw_contenedor" v-if="deleteRecordingsClientPriv">
                    <p>{{ $t('privileges.delete_recordings') }}</p>
                    <div class="sw_cam">
                      <input
                        type="checkbox"
                        id="swDeleteRec"
                        v-model="delRec"
                        class="switch_input"
                      />
                      <label for="swDeleteRec" class="switch_label"></label>
                    </div>
                  </div>
                  <div class="sw_contenedor" v-if="deleteCamerasClientPriv">
                    <p>{{ $t('privileges.edit_cameras') }}</p>
                    <div class="sw_cam">
                      <input
                        type="checkbox"
                        id="swModCameras"
                        v-model="delCam"
                        class="switch_input"
                      />
                      <label for="swModCameras" class="switch_label"></label>
                    </div>
                  </div>
                  <div class="sw_contenedor" v-if="editTasksClientPriv">
                    <p>{{ $t('privileges.edit_tasks') }}</p>
                    <div class="sw_cam">
                      <input
                        type="checkbox"
                        id="swModTask"
                        v-model="modSch"
                        class="switch_input"
                      />
                      <label for="swModTask" class="switch_label"></label>
                    </div>
                  </div>
                  <div class="sw_contenedor" v-if="editGroupsClientPriv">
                    <p>{{ $t('privileges.edit_groups') }}</p>
                    <div class="sw_cam">
                      <input
                        type="checkbox"
                        id="swModGroup"
                        v-model="modCamGroup"
                        class="switch_input"
                      />
                      <label for="swModGroup" class="switch_label"></label>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="cameras"
                role="tabpanel"
                aria-labelledby="cameras-tab"
              >
                <div class="conf_cameras">
                  <div class="cam_layout">
                    <div v-for="(camera, index) in this.$root.dataManager.cameras" :key="camera.id">
                      <camera-switch
                        :index="index"
                        :id="camera.id"
                        :name="camera.name"
                        :model="camera.model"
                      ></camera-switch>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="groups"
                role="tabpanel"
                aria-labelledby="groups-tab"
              >
                <div class="conf_cameras">
                  <div class="cam_layout">
                    <div v-for="(group, index) in this.$root.dataManager.groups" :key="group.id">
                      <group-switch
                        :index="index"
                        :id="group.id"
                        :name="group.name"
                        :geometry="group.geometry"
                      ></group-switch>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="labels"
                role="tabpanel"
                aria-labelledby="labels-tab"
              >
                <div class="conf_cameras">
                  <div class="cam_layout">
                    <div v-for="(label, index) in this.$root.dataManager.labels" :key="label.id">
                      <label-switch
                        :index="index"
                        :id="label.id"
                        :name="label.name"
                        
                      ></label-switch>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="btn_guardar">
              <button @click.prevent="save">
                <a href="#">{{ $t('common.save') }}</a>
                <div
                  v-if="saving"
                  class="spinner-border spinner-border-sm"
                  role="status"
                >
                  <span class="sr-only">Saving...</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <menu-footer active-state="users"></menu-footer>
</template>

<script>
import CameraSwitch from "../Cameras/CameraSwitch.vue";
import GroupSwitch from "../Groups/GroupSwitch.vue";
import LabelSwitch from "../Labels/LabelSwitch.vue";
import $ from "jquery";

const prAddDelCam = 0x01;
const prModPropCam = 0x02;
const prRecBajDem = 0x04;
const prPTZfreeJoy = 0x08;
const prPTZpresets = 0x10;
const prRecManage = 0x40;
const prRecDemanda = 0x80;
const prRecProgram = 0x0100;
const prRecAlarm = 0x0200;
const prRecDelImpor = 0x0800;
const prSeeSch = 0x4000;
const prAddDelSch = 0x8000;
const prModifSch = 0x010000;
const prCamManager = 0x400000;
const prRecExport = 0x080000;
const prRecProtect = 0x1000;

//const axios = require("axios").default;
//axios.defaults.withCredentials = false;

export default {
  name: "UserAdd",
  components: {
    CameraSwitch,
    GroupSwitch,
    LabelSwitch,
  },
  data() {
    return {
      //error: false,
      saving: false,
      errorMessage: "",
      cameras: [],
      groups: [],
      selectedCameras: [],
      selectedGroups: [],
      selectedInstallations: [],
      default_user_privileges: {},
      autouserpassword: false,
      name: "",
      surname: "",
      email: "",
      password: "",
      passwordVisibility: false,
      viewCam: true, // ver cámaras
      ptz: false, // control PTZ
      demandRec: false, // grabación bajo demanda
      viewRec: false, // ver grabación
      delRec: false, // borrar grabación
      exportRec: false, // exportar grabación
      modSch: false, // modificar tarea programada
      modCamGroup: false, //modificar grupo
      delCam: false, //modificar cámaras
      addCamerasClientPriv: false,
      editCamerasClientPriv: false,
      ptzClientPriv: false,
      onDemandRecClientPriv: false,
      showRecordingsClientPriv: false,
      exportRecordingsClientPriv: false,
      deleteCamerasClientPriv: false,
      deleteRecordingsClientPriv: false,
      editTasksClientPriv: false,
      editGroupsClientPriv: false,
    };
  },
  mounted() {
    // this.default_user_privileges = JSON.parse(
    //   sessionStorage.getItem("default_user_privileges")
    // );
    const userPriv = this.$root.sessionManager.privileges;
    console.log("UsersMain, userPriv:", userPriv);
    this.addCamerasClientPriv = userPriv["addCameras"];
    this.editCamerasClientPriv = userPriv["editCameras"];
    this.ptzClientPriv = userPriv["ptz"];
    this.onDemandRecClientPriv = userPriv["onDemandRec"];
    this.showRecordingsClientPriv = userPriv["showRecordings"];  
    this.exportRecordingsClientPriv = userPriv["exportRecordings"];  
    this.deleteCamerasClientPriv = userPriv["deleteCameras"];
    this.deleteRecordingsClientPriv = userPriv["deleteRecordings"];
    this.editTasksClientPriv = userPriv["editTasks"];
    this.editGroupsClientPriv = userPriv["editGroups"];

    this.default_user_privileges = this.$root.sessionManager.default_user_privileges;
    // this.autouserpassword = JSON.parse(
    //   sessionStorage.getItem("parameters")
    // ).autouserpassword;
    this.autouserpassword = this.$root.sessionManager.parameters.autouserpassword;
    console.log(
      "mounted add user,default_user_privileges",
      this.default_user_privileges
    );
    $("#msg-alert").hide();
    console.log("autouserpassword", this.autouserpassword);
    this.lodadDefaulPrivileges();
    //this.getCameras();
    //this.getGroups();
  },
  computed: {
    passwordFieldType() {
      return this.passwordVisibility ? "text" : "password";
    },
  },
  methods: {
    showMsgAlert() {
      $("#msg-alert")
        .fadeTo(200, .80, function() {
					setTimeout(function() {
						$("#msg-alert").fadeTo(200,0);
						},2000)
				});
    },
    closeWindow() {
      console.log("closeWindow");
      //this.$router.push({ name: "UsersMain" });
      this.$router.go(-1);
    },
    encodePrivCode() {
      let privcod = 0;
      if (this.viewCam) {
        privcod = privcod | prCamManager;
      }
      if (this.demandRec) {
        privcod = privcod | prRecBajDem;
      }
      if (this.viewRec) {
        privcod =
          privcod |
          prRecManage |
          prRecDemanda |
          prRecProgram |
          prRecAlarm |
          prRecProtect;
      }
      if (this.exportRec) {
        privcod = privcod | prRecExport;
      }
      if (this.delRec) {
        privcod = privcod | prRecDelImpor;
      }
      if (this.modCamGroup) {
        privcod = privcod | prModPropCam | prAddDelCam;
      }
      if (this.delCam) {
        privcod = privcod | prAddDelCam;
      }
      if (this.modSch) {
        privcod = privcod | prModifSch | prAddDelSch | prSeeSch;
      }
      if (this.ptz) {
        privcod = privcod | prPTZfreeJoy | prPTZpresets;
      }
      return privcod;
    },
    lodadDefaulPrivileges() {
      this.viewCam = this.default_user_privileges["viewcam"];
      this.ptz = this.default_user_privileges["ptz"];
      this.demandRec = this.default_user_privileges["demandrec"];
      this.viewRec = this.default_user_privileges["viewrec"];
      this.delRec = this.default_user_privileges["delrec"];
      this.exportRec = this.default_user_privileges["exportrec"];
      this.modSch = this.default_user_privileges["modsch"];
      this.modCamGroup = this.default_user_privileges["modcamgroup"];
      this.delCam = this.default_user_privileges["delcam"];
    },
    switchPasswordVisibility() {
      console.log("switchPasswordVisibility");
      this.passwordVisibility = !this.passwordVisibility;
    },
    verifyData() {
      if (this.name === "") {
        this.errorMessage = this.$t('users.first_name_required');
        this.error = true;
        return false;
      }
      if (this.surname === "") {
        this.errorMessage = this.$t('users.surname_required');
        this.error = true;
        return false;
      }
      if (this.email === "") {
        this.errorMessage = this.$t('users.email_required');
        this.error = true;
        return false;
      }
      if (!this.email.includes("@") || this.email.length < 4) {
        this.errorMessage = this.$t('users.email_format_invalid');
        this.error = true;
        return false;
      }
      //const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;
      const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()-_=+{};:'",.<>?_`|~]{8,}$/;
      if (!this.autouserpassword && !regex.test(this.password)) {
        this.errorMessage = this.$t('users.password_no_valid');
        this.error = true;
        return false;
      }
      return true;
    },
    save() {
      console.log("save user");
      this.errorMessage = "";
      this.error = false;
      this.selectedCameras = $("input:checkbox[name=swCamSwitch]:checked")
        .map(function () {
          console.log("Id: " + $(this).attr("id") + " Value: " + $(this).val());
          return $(this).attr("id").split("-")[1];
        })
        .get();
      this.selectedGroups = $("input:checkbox[name=swGroupSwitch]:checked")
        .map(function () {
          console.log("Id: " + $(this).attr("id") + " Value: " + $(this).val());
          return $(this).attr("id").split("-")[1];
        })
        .get();
        this.selectedInstallations = $("input:checkbox[name=swLabelSwitch]:checked")
        .map(function () {
          console.log("Id: " + $(this).attr("id") + " Value: " + $(this).val());
          return $(this).attr("id").split("-")[1];
        })
        .get();
      console.log("ptz", this.ptz);
      console.log("viewCam", this.viewCam);

      if (this.verifyData()) {
        console.log("verify data OK");
        this.saveUser();
      } else {
        this.showMsgAlert();
      }
    },

    async saveUser() {
      this.saving = true;
      console.log("saveUser");
      try {
        await this.$root.dataManager.addUser(this.email,this.password,this.name,this.surname,this.encodePrivCode(),this.selectedCameras.toString(),this.selectedGroups.toString(),this.selectedInstallations.toString());
        this.saving=false;
        this.$router.push({ name: "UsersMain" });
      }
      catch (error) {
        console.log("saveUser error");
        console.error(error);
        //this.error = true;
        this.errorMessage = this.$t('users.error_saving_user');
        this.showMsgAlert();
      }
    },

  },
};
</script>

<style scoped>
.sw_prog .sw_contenedor p {
  width: 320px;
  text-align: left;
}
</style>
