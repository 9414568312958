<template>
  <div class="cam_vista_min" v-on:click="viewGroup(id, name)">
<div class="group_preview">
	<img v-if="(preview.textOrImage==2)"      
      :src="preview.previewImg"
      alt=""
    />
		<span class="preview_msg" v-show="(preview.textOrImage==1)" v-html="preview.previewText"></span>
		</div>
    
    <div class="cam_menu">
      <div class="txt_cam">
        <h3 class="text-truncate">{{ name }}</h3>
        <p class="text-truncate">{{ geometry }}</p>
      </div>
      <div class="m_dot">
        <!-- Default dropstart button -->
        <div class="btn-group dropstart">
          <button v-on:click.stop
            type="button"
            class="btn btn-no-border"
            data-bs-toggle="dropdown"
            aria-expanded="false"
						ref="dropdownbtn"
          >
            <span
              ><svg
                id="Modo_de_aislamiento"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1.78 10.75"
              >
                <circle
                  class="cls-1"
                  cx=".89"
                  cy="9.86"
                  r=".89"
                  transform="translate(-2.23 .46) rotate(-13.2)"
                />
                <circle
                  class="cls-1"
                  cx=".89"
                  cy="5.37"
                  r=".89"
                  transform="translate(-1.2 .35) rotate(-13.2)"
                />
                <circle
                  class="cls-1"
                  cx=".89"
                  cy=".89"
                  r=".89"
                  transform="translate(-.18 .23) rotate(-13.2)"
                /></svg></span>
          </button>
          <ul class="dropdown-menu list-dropdown">
            <li v-if="editGroupsPriv">
              <a @click.stop="$emit('deleteEvent', id, index)"
                ><i class="fa fa-trash" aria-hidden="true"></i> {{ $t('common.delete') }}</a
              >
            </li>
            <li>
              <a @click.stop="updatePreview(id)"
                ><i class="fa fa-refresh" aria-hidden="true"></i> {{ $t('common.update_preview') }}</a
              >
            </li>
            <li v-if="editGroupsPriv">
              <a @click.stop="$emit('editEvent', id, index)"
                ><i class="fa fa-pencil" aria-hidden="true"></i> {{ $t('common.edit') }}</a
              >
            </li>
            <!--<li>
              <a @click.stop="viewGroup(id, name)"
                ><i class="fa fa-eye" aria-hidden="true"></i> Ver</a
              >
            </li>-->
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//const axios = require("axios").default;
//axios.defaults.withCredentials = false;
//const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
export default {
  name: "GroupItem",
  props: {
    index: Number,
    id: Number,
    name: {
      type: String,
      required: true,
      default: "",
    },
    geometry: {
      type: String,
      default: "",
    },
    interval: {
      type: String,
      default: "",
    },
  },
  emits: ["deleteEvent", "editEvent"],
  data() {
    return {
      //previewURL: "",
      loaded: false,
      error: false,
      editGroupsPriv: false,
			previewText: "", 
			previewUpdating: this.$t('groups.updating_preview'),
    };
  },
  computed: {
    preview() {
      //console.log("compute preview");
      let pr={};
      pr.textOrImage=0;
      pr.previewText="";
      pr.previewImg="";
      if (this.previewText!="") {
        pr.textOrImage=1;
        pr.previewText=this.previewText;
        pr.previewImg="";
        return pr;
      }
      if (this.$root.dataManager.groupPreviews["group" + this.id]===undefined)
      {
        pr.previewText="";
        pr.previewImg="pending";
        pr.textOrImage=0;
        return pr;
      }
      if (this.$root.dataManager.groupPreviews["group" + this.id]==="downloading")
      {
        pr.previewText="";
        pr.previewImg="";
        pr.textOrImage=0;
        return pr;
      }
      if (this.$root.dataManager.groupPreviews["group" + this.id]==="unavailable")
      {
        pr.previewText=this.$t('groups.no_preview_available') + "<br>" + this.$t('groups.select_update_preview');
        pr.textOrImage=1;
        return pr;
      }
      pr.previewText="";
      pr.previewImg=this.$root.dataManager.groupPreviews["group" + this.id];
      pr.textOrImage=2;
      return pr;
    }
  },
  watch: {
    preview() {
      if (this.preview.previewImg=="pending") {
        this.getPreview(this.id);
      }
      
    }
  },

  created() {
    console.log("GropupItem created");
    //this.getPreviewURL(this.id);
    this.getPreview(this.id);
  },
  mounted() {
    const userPriv = this.$root.sessionManager.privileges;
    console.log("UsersMain, userPriv:", userPriv);
    this.editGroupsPriv = userPriv["editGroups"];
		this.$refs.dropdownbtn.addEventListener('show.bs.dropdown', function() {
			var oldMenu=document.querySelector('.dropdown-menu.show');
			if (oldMenu!=null) oldMenu.classList.remove("show");
		});

  },
  methods: {
		openCentered(url, parent, width, height) {
			const y = parent.top.outerHeight / 2 + parent.top.screenY - ( height / 2);
			const x = parent.top.outerWidth / 2 + parent.top.screenX - ( width / 2);
      var w=window.open(
        url,
        "",
        "height=" + height + ",width=" + width + ",status=yes,toolbar=no,menubar=no,location=no,scrollbars=0,resizable=1,top=" + y + ", left=" + x
      );		
			w.desiredWidth=width;
			w.desiredHeight=height;	
			return w;	
		},
    viewGroup(id, name) {
      console.log("viewGroup groupid:", id, name);

      let viewer = localStorage.getItem("Viewer");

      if (viewer == null || viewer == "web") {
        const url = "view_group.html?groupid=" + id + "&groupname=" + name;
        var newWindow=this.openCentered(url,window,960,540);
        newWindow.parent=window;

      }
      else{
        var buffer = require('buffer/').Buffer;
        var params = "dispatcher=" + sessionStorage.getItem("sessionManager.dispatcher") + "\r\n";
        params += "sessionid=" + sessionStorage.getItem("sessionManager.sessionId") + "\r\n";
        params += "action=2"  + "\r\n"; //group
        params += "id=" + id + "\r\n";
        params += "setupfile=" + this.APIServer + "/files/"  + this.$t("settings.app_name") + ".exe"  + "\r\n";
        params += "reqversion="  + "8.0.0.0"  + "\r\n";
        params += "language="  + sessionStorage.getItem("sessionManager.language")  + "\r\n";
        params += "hotcolor=#55C1FF"  + "\r\n";
        params += "mdmarkscolor=#FF0000"  + "\r\n";
        console.log("params:")
        console.log(params);
        const url = "ipnvideo:" + buffer.from(params).toString('base64')
        console.log("location:",url)
        window.location.href = url

      }
    },
    async updatePreview(id) {
			document.querySelector('.dropdown-menu.show').classList.remove("show");
      //const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      //console.log("updatePreview:", userInfo.sessionId);
      //sessionStorage.removeItem("previewGroup" + id + ":" + userInfo.sessionId);

      //const url = userInfo.APIUrl + "/groups/" + id + "/preview/?q=" + userInfo.sessionId;
      //console.log(url);
      //this.previewURL = "/img/updating_preview0.jpg";
      this.$root.dataManager.deleteGroupPreview(id);
      //this.previewURL="";
			this.previewText = this.previewUpdating + "<br>&nbsp;";
      //await sleep(1200);
      let times=0;
      let self=this;
      let interval=setInterval(function() {
        times++;
        if (times<20) {
          self.previewText = self.previewUpdating + "<br>" + ".".repeat(times);
        }
      },1000);
      try {
        //this.previewURL = "/img/updating_preview1.jpg";
				//this.previewText = this.previewUpdating + "<br>.";
        await this.$root.dataManager.updateGroupPreview(id);
        //this.previewText = this.previewUpdating + "<br>..";
        console.log("updatePreview OK");
        //await sleep(1000);
        //this.previewText = this.previewUpdating + "<br>...";
        clearInterval(interval);
        this.previewText = "";
        //this.getPreviewURL(id);
        //this.getPreview(id);
      } catch (error) {
        clearInterval(interval);
        console.log("error updating preview");
        this.previewText = this.$t('groups.error_updating_preview');
      }
    },
    async getPreview(id) {
      try {
        await this.$root.dataManager.getGroupPreviewBase64(id);
        this.previewText="";
      }
      catch (error) {
        console.log(error);
      }

    },


  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*important top:-155px to see complete menu in large list of groups*/
.dropstart .dropdown-menu.show {
  /*inset: initial !important;
  display: block;
  transform: none !important;*/
  top: -155px !important;
  transform: translate(-30px,0)!important;
  /*right: -9px !important;
  padding: 0px;
  border: none;
  box-shadow: 3px 4px 3px -1px #0000002e;
  border: solid #f0f0f1 1px;
  border-radius: 0px !important;*/
}
</style>