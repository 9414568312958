<template>
  <div
    class="offcanvas offcanvas-start ml_items"
    tabindex="-1"
    id="offcanvasNavbar"
    data-bs-backdrop="false"
    data-bs-scroll="true"
    aria-labelledby="offcanvasNavbarLabel"
    ref="mainMenu"
  >
    <div class="close_button">
      <button
        type="button"
        class="text-reset material-icons close_props"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      >
        close
      </button>
    </div>
    <div id="canvas-header" class="offcanvas-header contenedor_cabecera_menu">
      <!--<img id="image-header" src="../../../public/img/logo.png" />-->
      <div id="logo"></div>
      <a
        v-if="
          !(
            this.$root.sessionManager.isLoggedIn() &&
            this.$root.sessionManager.isLoggedInAsOperator()
          )
        "
        @click="logOut"
        href="#"
        >{{ $t("menus.close_session") }}</a
      >
      <a
        v-if="
          this.$root.sessionManager.isLoggedIn() &&
          this.$root.sessionManager.isLoggedInAsOperator()
        "
        @click="changeClient"
        href="#"
        >{{ $t("menus.exit_client") }}</a
      >
    </div>

    <div class="offcanvas-body">
      <ul
        v-if="this.$root.sessionManager.isLoggedIn()"
        class="navbar-nav justify-content-end flex-grow-1 pe-3"
      >
        <li class="nav-header">
          <!--<a class="nav-link" aria-current="page" href="">-->
          <!--<i class="fa fa-video-camera" aria-hidden="true"></i>-->
          <span>{{ $t("common.labels") }}</span>
          <!--</a>-->

          <!--<div style="position:absolute;right:20px" >
            <a href="">
              <span class="material-icons" aria-hidden="true">add</span>
                {{name}}
            </a>
          </div>-->
          <a href="#" @click="this.$router.push({ name: 'LabelsMain' })">
            <span class="material-icons" aria-hidden="true">edit</span>
          </a>
        </li>
        <label-menu-item
          @click.stop
          :id="-1"
          :alarmState="1"
          @selected-event="selectLabel"
          >{{ $t("menus.all") }}</label-menu-item
        >
        <!--<template v-for="(label) in $root.dataManager.labels" :key="label.id">-->
        <label-menu-item
          v-for="label in $root.dataManager.labels"
          :key="label.id"
          @click.stop
          :id="label.id"
          :alarmState="label.alarmState"
          @selected-event="selectLabel"
          >{{ label.name }}
        </label-menu-item>
        <!--</template>-->
        <!--<li
          class="nav-item"
          @click="this.$router.push({ name: 'CamerasMain' })"
        >
          <a class="nav-link" aria-current="page" href="">
            <i class="fa fa-video-camera" aria-hidden="true"></i>
            Cámaras
          </a>
        </li>-->

        <!--<li class="nav-item" @click="this.$router.push({ name: 'GroupsMain' })">
          <a class="nav-link" aria-current="page" href="">
            <i class="fa fa-object-group" aria-hidden="true"></i>
            Grupos
          </a>
        </li>
        <li
          class="nav-item"
          @click="this.$router.push({ name: 'RecordingsMain' })"
        >
          <a class="nav-link" href="">
            <i class="fa fa-film" aria-hidden="true"></i>
            Grabaciones
          </a>
        </li>-->
        <li class="nav-header"></li>
        <li
          v-if="exportRecordingsPriv"
          class="nav-item"
          @click="this.$router.push({ name: 'ExportsMain' })"
        >
          <a class="nav-link" href="#" @click.prevent
            ><i class="material-icons" aria-hidden="true">download</i
            >{{ $t("common.exports") }}</a
          >
        </li>
        <li
          class="nav-item"
          @click="this.$router.push({ name: 'NotificationsMain' })"
        >
          <a class="nav-link" href="#" @click.prevent
            ><i class="material-icons" aria-hidden="true">notifications</i
            >{{ $t("common.notifications") }}</a
          >
        </li>
        <!--<li class="nav-item" @click="this.$router.push({ name: 'TasksMain' })">
          <a class="nav-link" href="#">
            <i class="material-icons" aria-hidden="true">today</i>
            Programaciones
          </a>
        </li>-->
        <li id="drop" class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            ><i class="material-icons" aria-hidden="true">person</i
            >{{ $t("menus.my_account") }}</a
          >
          <ul class="dropdown-menu">
            <li
              v-if="editUsersPriv"
              @click="this.$router.push({ name: 'UsersMain' })"
            >
              <a
                id="item_drop"
                class="dropdown-item"
                data-bs-dismiss="offcanvas"
                href="#"
                @click.prevent
                ><i class="material-icons" aria-hidden="true">groups</i
                >{{ $t("menus.users") }}</a
              >
            </li>
            <li @click="this.$router.push({ name: 'PasswordChange' })">
              <a id="item_drop" class="dropdown-item" href="#" @click.prevent
                ><i class="material-icons" aria-hidden="true">key</i
                >{{ $t("menus.change_password") }}</a
              >
            </li>
            <li @click="this.$router.push({ name: 'TwoFactor' })">
              <a id="item_drop" class="dropdown-item" href="#" @click.prevent
                ><i class="material-icons" aria-hidden="true">mail</i
                >{{ $t("menus.two_factor") }}</a
              >
            </li>
            <li
              v-show="isWindows"
              @click="this.$router.push({ name: 'ViewerConfig' })"
            >
              <a id="item_drop" class="dropdown-item" href="#" @click.prevent
                ><i class="material-icons" aria-hidden="true"
                  >display_settings</i
                >{{ $t("menus.viewer_config") }}</a
              >
            </li>
            <li @click="this.$router.push({ name: 'Settings' })">
              <a id="item_drop" class="dropdown-item" href="#" @click.prevent
                ><i class="material-icons" aria-hidden="true">settings</i
                >{{ $t("menus.settings") }}</a
              >
            </li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/files/manual.pdf" target="_blank"
            ><i class="material-icons" aria-hidden="true">description</i
            >{{ $t("menus.user_manual") }}</a
          >
        </li>
      </ul>
    </div>

    <div class="copyright_menu">
      <h5>{{ $t("common.version") }}: {{ Version }}</h5>
      <h5>{{ $t("common.copyright", { year: new Date().getFullYear() }) }}</h5>
      <h6>{{ $t("common.all_rights_reserved") }}</h6>
    </div>
  </div>
</template>

<script>
//import bootstrapBundle from "bootstrap/dist/js/bootstrap.bundle";
import LabelMenuItem from "../Labels/LabelMenuItem.vue";
const Swal = require("sweetalert2");
import DataManager from "../../assets/custom/js/DataManager.js";
export default {
  name: "MenuMain",
  emits: ["labelSelectedEvent"],
  components: {
    LabelMenuItem,
  },
  data() {
    return {
      editUsersPriv: false,
      exportRecordingsPriv: false,
      isWindows: false,
    };
  },
  mounted() {
    //var self=this;
    this.isWindowsClient();
    const userPriv = this.$root.sessionManager.privileges;
    this.editUsersPriv = userPriv["editUsers"];
    this.exportRecordingsPriv = userPriv["exportRecordings"];
    if (this.$root.sessionManager.isLoggedIn()) this.getLabels();
    /*this.$root.dataManager.loadLabels()
      .then (() => {
        //self.labelItemList=self.dataManager.labelItemList;
        //console.log(this.dataManager.labelItemList);
      })
      .catch(error => {
        console.log(error);
      });*/
  },
  unmounted() {
    console.log("unmounted MenuMain");
  },
  created() {
    console.log("created");
    //const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    //this.dataManager.APIUrl=userInfo.APIUrl;
    //this.dataManager.sessionId=userInfo.sessionId;
    //this.dataManager=new DataManager(userInfo.APIUrl,userInfo.sessionId);
    //console.log(this.dataManager);
  },
  computed: {},
  methods: {
    async getLabels() {
      try {
        await this.$root.dataManager.loadLabels();
      } catch (response) {
        if (response.result == DataManager.S_SESSIONERROR) {
          Swal.fire({
            text: "Sesión finalizada",
            icon: "error",
            confirmButtonText: "Aceptar",
            position: "center",
          }).then((result) => {
            console.log(result);
            if (result.isConfirmed) {
              this.logOut();
            }
          });
        }
      }
    },
    selectLabel(id) {
      console.log("select label", id);
      this.$root.dataManager.selectedLabelId = id;
      //sessionStorage.setItem("labelId",id.toString());
      //this.$emit('labelSelectedEvent',id);
    },
    isWindowsClient() {
      let os = navigator.userAgent;
      console.log("userAgent:", os);
      console.log(os.search("Windows"));
      if (os.search("Windows") !== -1) {
        this.isWindows = true;
        return;
      }
      this.isWindows = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.nav-header {
  color: var(--main-menu-text-color);
}

.text-reset {
  color: var(--main-menu-text-color) !important;
}

/* #drop = Element in main menu that shows the dropdown */
/* #item_drop = Element in the dropdown menu*/

/*#drop:hover {
  background-color: var(--main-menu-hover-background-color);
	color: var(--main-menu-hover-color);
}

#drop:hover > i {
	color: var(--main-menu-hover-color);
}*/

#item_drop {
  color: var(--main-menu-text-color) !important;
  font-weight: bold;
  width: 100%;
  font-size: 16px;
}
#item_drop > i {
  color: var(--main-menu-text-color) !important;
}

#item_drop:hover > i {
  color: var(--main-menu-hover-color) !important;
  /*transition: 0.3s ease;*/
}
#item_drop:hover {
  color: var(--main-menu-hover-color) !important;
  background-color: var(--main-menu-hover-background-color);
  /*transition: 0.3s ease;*/
}

#item_drop,
#item_drop > i {
  transition: 0.3s ease;
}

/*.dropdown-toggle::after {
  color: var(--main-menu-text-color);
}*/

.dropdown-menu {
  background-image: linear-gradient(
    to right,
    var(--main-menu-left-color),
    var(--main-menu-right-color)
  );
  box-shadow: 3px 4px 3px -1px var(--shadow-color);
}

.navbar-nav .nav-link {
  /*color: var(--main-menu-text-color);*/
  font-weight: bold;
  width: 100%;
  font-size: 16px;
  transition: none;
}

.offcanvas {
  padding: 12px 0;
}
.offcanvas.offcanvas-start {
  /*border-right: var(--bs-offcanvas-border-width) solid var(--separator-color);*/
  border: none;
  box-shadow: 5px 0px 17px 1px var(--shadow-color);
}

.offcanvas-backdrop.show {
  opacity: 0.5;
  width: 100%;
  height: 100%;
}

.ml_items {
  /*background-image: linear-gradient(to right, white, #e6ebf8);*/
  /*background-image: linear-gradient(to right, white, #f0f0f0);*/
  /*background-image: linear-gradient(to right, var(--bottombar-background-color), var(--topbar-background-color));*/
  background-image: linear-gradient(
    to right,
    var(--main-menu-left-color),
    var(--main-menu-right-color)
  );
}

.offcanvas-body ul li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 16px;
  margin-bottom: 0.7rem;
  transition: none;
}

.offcanvas-body .dropdown-menu li {
  padding-left: 0;
}

.offcanvas-body ul li a {
  text-align: left;
}

.offcanvas-body li i {
  padding-right: 18px;
  color: var(--main-menu-text-color);
  /*font-size: 18px;*/
  font-size: 22px;
  vertical-align: middle;
  transition: none;
}

.pe-3 {
  padding-top: 2rem;
  padding-right: 0px !important;
}

.ml_items .nav-item,
.ml_items .nav-item .nav-link:hover,
.ml_items .nav-item .nav-link i,
.ml_items .nav-item .nav-link::after {
  transition: 0.3s ease !important;
}

.ml_items .nav-item,
.ml_items .nav-item a::after {
  /*background-color: #61aaea;*/
  /*background-color: var(--main-menu-accent-color);*/
  color: var(--main-menu-text-color);
  /*transition:3s ease;*/
}

.ml_items .nav-item:hover {
  /*background-color: #61aaea;*/
  background-color: var(--main-menu-hover-background-color);
  color: var(--main-menu-hover-color);
}

.ml_items .nav-item:hover i,
.ml_items .nav-item:hover a,
.ml_items .nav-item:hover a::after,
.ml_items .nav-item:focus a {
  color: var(--main-menu-hover-color) !important;
  /*transition: .3s ease;*/
  transition: none;
}

.ml_items .nav-item:hover ul li {
  /*transition: .3s ease;*/
  transition: none;
}
.ml_items .nav-item:hover ul li i,
.ml_items .nav-item:hover ul li a {
  color: var(--main-menu-hover-color) !important;
  /*transition: .3s ease;*/
  transition: none;
}

.close_button {
  display: flex;
  justify-content: right;
}

.copyright_menu {
  padding: 0.5rem 1.5rem;
  color: var(--main-menu-text-color);
}

.copyright_menu h5 {
  font-size: 11px;
  font-weight: 900;
  margin-bottom: 6px;
}

.copyright_menu h6 {
  font-size: 10px;
  font-weight: 100;
}

.contenedor_cabecera_menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: var(--main-menu-logo-alignment);
  padding-top: 30px;
}

/*.contenedor_cabecera_menu img {
    width: 200px;
    margin-bottom: 17px;
}*/

.contenedor_cabecera_menu #logo {
  width: 200px;
  height: 44px;
  background-image: var(--menu-logo-img);
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 17px;
}

.contenedor_cabecera_menu a {
  font-size: 12px;
  color: var(--main-menu-text-color);
  font-weight: bold;
  text-decoration: none;
}

.contenedor_cabecera_menu a:hover {
  color: var(--main-menu-link-hover-color);
}

.dropdown-menu {
  --bs-dropdown-border-color: var(--main-menu-separator-color) !important;
}

.nav-header {
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px solid var(--main-menu-separator-color);
  padding-bottom: 5px;
}
.nav-header > span {
  width: 100%;
}
.nav-header a {
  color: var(--main-menu-text-color);
  vertical-align: middle;
  /*position:absolute;*/
  right: 12px;
  text-decoration: none;
  line-height: 1;
}
.nav-header a span {
  font-size: 24px;
}

.offcanvas-body {
  /*height: 50vh;*/
  height: calc(100vh - 400px);
  overflow-y: auto;
  width: 100%;
  /*-ms-overflow-style: none; */
  /*scrollbar-width: none;  */
  scrollbar-width: thin;
  /*max-height: 450px;*/
  min-height: 250px;
  padding: 0 12px;
}
.offcanvas-body::-webkit-scrollbar {
  /*display: none;*/
  width: 8px;
  /*background: #f0f0f0;*/
  background: transparent;
}
.offcanvas-body::-webkit-scrollbar-thumb {
  /*background: #cdcdcd;*/
  background: var(
    --main-menu-separator-color
  ); /*rgba(var(--main-text-color-triplet),.15);*/
  opacity: 0.5;
  -webkit-border-radius: 1ex;
  border-radius: 1ex;
  /*-webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);*/
}

.offcanvas-body::-webkit-scrollbar-corner {
  background: transparent;
}

@media (max-width: 600px) {
  .nav-header {
    font-size: 12px;
  }
  .nav-header a span {
    font-size: 20px;
  }
  .offcanvas-body {
    padding: 0 6px;
  }
  .navbar-nav .nav-link {
    font-size: 14px;
  }
  .offcanvas-body li i {
    padding-right: 14px;
    font-size: 18px;
  }
  #item_drop {
    font-size: 14px;
  }
  .offcanvas-body ul li {
    padding-left: 6px;
  }
}

/*@media (max-width: 375px) {
    .offcanvas-body {
      padding: 0 12px;
    }
}*/
</style>
