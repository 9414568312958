<template>
  <menu-main></menu-main>

  <div id="groups">
    <header-main> </header-main>
    <div id="main_body" class="cuerpo_principal">
      <div class="mosaico_cam">
        <div class="main_content">
          <div class="grid_busc">
            <form class="buscador">
              <input
                id="search"
                type="search"
                v-model="search"
                :placeholder="$t('common.search')"
              />
              <!--<button><i class="fa fa-search" aria-hidden="true"></i></button>-->
              <span class="material-icons refresh-button"
                @click="refresh">refresh</span>
            </form>
          </div>
          <p v-if="this.$root.dataManager.groupsLoaded && !this.$root.dataManager.groupsWithLabel.length && this.$root.dataManager.selectedLabelId==-1" class="no_data_msg">
            {{ $t('groups.no_groups') }}
          </p>
          <p v-if="this.$root.dataManager.groupsWithLabelLoaded && !$root.dataManager.groupsWithLabel.length && this.$root.dataManager.selectedLabelId!=-1" class="no_data_msg">
            {{ $t('groups.no_groups_with_label') }}
          </p>
          <div class="grid_group">
            <div v-for="(group, index) in filterGroups" :key="group.id">
              <group-item
                :index="index"
                :id="group.id"
                :name="group.name"
                :interval="group.interval"
                :geometry="group.geometry"
                @delete-event="showDeleteConfirmation"
                @edit-event="editGroup"
              >
              </group-item>
            </div>
          </div>
        </div>
        <button
            v-show="editGroupsPriv"
            @click="addGroup"
            class="agregar_opcion"
          >
          <span class="material-icons">add</span>
          </button>
      </div>
    </div>
  </div>

  <menu-footer active-state="groups"></menu-footer>
</template>

<script>
import GroupItem from "./GroupItem.vue";
//import DataManager from "../../assets/custom/js/DataManager.js";

//const axios = require("axios").default;
//axios.defaults.withCredentials = false;
const Swal = require("sweetalert2");

export default {
  name: "GroupsMain",
  components: {
    GroupItem,
  },
  data() {
    return {
      sessionId: "",
      //groups: [],
      search: "",
      error: false,
      //loaded: false,
      editGroupsPriv: false,
    };
  },
  computed: {
    filterGroups() {
      return this.search
        ? this.$root.dataManager.groupsWithLabel.filter((group) =>
            group.name.toLowerCase().includes(this.search.toLowerCase())
          )
        : this.$root.dataManager.groupsWithLabel;
    },
  },
  methods: {
    async refresh() {
      console.log("refresh");
      // const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      // for (const group of this.$root.dataManager.groups) {
      //   sessionStorage.removeItem("previewGroup" + group.id + ":" + userInfo.sessionId);
      // }
      this.$root.dataManager.deleteAllGroupPreviews();
      this.$root.dataManager.loadGroups(true);
      if (this.$root.dataManager.selectedLabelId!=-1)
        this.$root.dataManager.loadGroupsWithLabel(true);
    },
    showDeleteConfirmation(id, index) {
      console.log("showDeleteConfirmation:", id, index);
      var groupName = ""
      this.$root.dataManager.groupsWithLabel.forEach((group) => {
          if (id == group.id)
                groupName=group.name
      });
      Swal.fire({
        /*title: this.recordings[index].camName,*/
        text: this.$t('groups.group_del_confirmation',{groupname: groupName}),
        icon: "question",
        showCancelButton: true,
        confirmButtonText: this.$t('common.ok'),
        cancelButtonText: this.$t('common.cancel'),
        //confirmButtonColor: getComputedStyle(document.documentElement).getPropertyValue('--main-accent-color') ,
        //cancelButtonColor: getComputedStyle(document.documentElement).getPropertyValue('--main-inactive-color'),
        position: "center",
      }).then((result) => {
        if (result.isConfirmed) {
          console.log("Delete:", id, index);
          this.deleteGroup(id);
        }
      });
    },
    async deleteGroup(id) {
      if (!this.editGroupsPriv) return;
      try {
        await this.$root.dataManager.deleteGroup(id);
        //await this.$root.dataManager.loadGroups(true);       
      } catch (error) {
        console.log("group delete ERROR", error);
      }

      
    },
    addGroup() {
      console.log("add group");
      if (this.editGroupsPriv) {
        this.$router.push({ name: "GroupAdd" });
      }
    },
    editGroup(id, index) {
      console.log("editGroup:", id, index);
      if (this.editGroupsPriv) {
        this.$router.push({ name: "GroupEdit", params: { editId: id } });
      }
    },
  },
  mounted() {
    document.documentElement.style.scrollBehavior = 'auto';
    console.log("GroupsMain");
    document.getElementById("search").focus();
    document.getElementById("search").blur();
    document.title = this.$t('common.groups');
    //const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (!this.$root.sessionManager.isLoggedIn()) {
      console.log("router to signin");
      this.$router.push({ name: "SignIn" });
    }
    console.log("GroupsMains, sessionid:", this.$root.sessionManager.sessionId);
    this.sessionId = this.$root.sessionManager.sessionId;
    const userPriv = this.$root.sessionManager.privileges;
    console.log("UsersMain, userPriv:", userPriv);
    this.editGroupsPriv = userPriv["editGroups"];
    //this.getGroups();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>