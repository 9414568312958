const ar = {
    common: {
        save: "حفظ",
        delete: "حذف",
        update_preview: "تحديث المعاينة",
        edit: "تحرير",
        ok: "موافق",
        cancel: "إلغاء",
        name: "اسم",
        date: "تاريخ",
        start: "ابدأ",
        end: "نهاية",
        no_privileges: "مستخدم بدون امتيازات لهذه الوظيفة",
        time: "الوقت",
        session_expired: "صلاحية الجلسة انتهت",
        cameras: "الكاميرات",
        configuration: "التكوين",
        hours: "ساعات",
        minutes: "دقائق",
        seconds: "ثواني",
        groups: "مجموعات",
        information: "المعلومات",
        yes: "نعم",
        no: "لا",
        copyright: "",
        all_rights_reserved: "جميع الحقوق محفوظة",
        recordings: "التسجيلات",
        tasks: "المهام",
        labels: "التسميات",
        exports: "الصادرات",
        notifications: "تبليغات",
        search: "بحث",
        filters: "فلاتر أو مرشحات",
        users: "المستخدمون",
        version: "الإصدار",
        activate: "التفعيل",
    },

    weekdays: {
        monday: "الاثنين",
        tuesday: "الثلاثاء",
        wednesday: "الأربعاء",
        thursday: "الخميس",
        friday: "الجمعة",
        saturday: "السبت",
        sunday: "الأحد",
        all: "كل الأسبوع",



    },
    cameras: {
        new_camera: "كاميرا جديدة",
        information: "المعلومات",
        connection: "الاتصال",
        camera_name: "اسم الكاميرا",
        mac: "عنوان ماك",
        camera_mac: "ماك من الكاميرا",
        vcode: "فكود",
        camera_vcode: "فكود الكاميرا",
        user: "المستخدم",
        camera_user: "مستخدم الكاميرا",
        password: "كلمة المرور",
        camera_password: "كلمة مرور الكاميرا",
        profile: "الملف الشخصي",
        name_required: "الاسم المطلوب",
        mac_required: "ماك المطلوب",
        vcode_required: "فكود المطلوب",
        user_required: "المستخدم المطلوب",
        password_required: "كلمة المرور المطلوبة",
        review_connection_params: "مراجعة معلومات الاتصال",
        motion: "الحركة",
        video: "فيديو",
        audio: "الصوت",
        channel: "القناة ",
        brand: "العلامة التجارية",
        camera_brand: "الشركة المصنعة للكاميرا",
        model: "نموذج",
        camera_model: "نموذج الكاميرا",
        ip_dn: "اب/ دن",
        camera_ip_dn: "اب/ دن من الكاميرا",
        camera_profile: "ملف تعريف الكاميرا",
        detection: "كشف",
        sensitivity: "حساسية",
        low: "منخفض",
        medium: "متوسط",
        high: "عالي",
        region: "المنطقة",
        full: "كامل",
        custom: "مخصص",
        enable: "تمكين",
        error_updating_camera: "خطأ في تحديث الكاميرا",
        edit_camera: "تحرير الكاميرا",
        setup_wifi: "إعداد واي فاي",
        updating_preview: "تحديث المعاينة",
        no_preview_available: "لا تتوفر المعاينة",
        select_update_preview: "حدد معاينة التحديث",
        error_updating_preview: "خطأ في تحديث المعاينة",
        no_cameras: "لا توجد كاميرات متوفرة في النظام",
        no_cameras_with_label: "لا توجد كاميرات بعلامة محددة",
        cam_del_confirmation: "هل ترغب في حذف الكاميرا؟[اسم الكاميرا]",
        no_licenses_for_new_camera: "ليس لديك تراخيص كافية لإضافة كاميرا جديدة",
        cameras: "كاميرات",
        security: "الأمن",
        searching_wifi: "البحث عن شبكات واي فاي...",
        configuring_the_camera_wait:"تكوين الكاميرا. يرجى الانتظار وعدم مغادرة الصفحة...",
        error_getting_wifi: "خطأ في الحصول على معلمات واي فاي للكاميرا",
        error_getting_network_list: "خطأ في الحصول على قائمة الشبكات المتاحة",
        new_password_sent: "تم إرسال كلمة مرور جديدة",
        please_wait: "من فضلك انتظر...",
        configuring_wifi: "تكوين شبكة واي فاي للكاميرا",
        camera_configured_successfully: "تم تكوين الكاميرا بنجاح",
        error_configuring_wifi: "خطأ في تكوين شبكة واي فاي",
        wifi_config: "تكوين واي فاي",
        wifi_password: "كلمة مرور واي فاي",
        enter_password: "أدخل كلمة المرور",
        passwords_dont_match: "كلمات المرور غير متطابقة",
        repeat_password: "كرر كلمة المرور",

    },
    exports: {
        export_name: "اسم التصدير",
        export: "تصدير",
        export_name_required: "اسم التصدير مطلوب",
        invalid_start_time: "وقت بدء التشغيل غير صالح",
        invalid_end_time: "وقت انتهاء غير صالح",
        review_export_params: "مراجعة معلمات التصدير",
        download: "تحميل",
        no_exports: "لا توجد صادرات متوفرة في النظام",
        export_del_confirmation: "هل ترغب في حذف تصدير الكاميرا (اسم الكاميرا )؟",
    },

    groups: {
        interval: "الفاصل الزمني",
        group_name: "اسم المجموعة",
        geometry: "الهندسة",
        name_required: "اسم المجموعة مطلوب",
        at_least_one_camera: "يجب عليك اختيار كاميرا واحدة على الأقل",
        error_saving_group: "خطأ في حفظ المجموعة",
        error_updating_group: "خطأ في تحديث المجموعة",
        new_group: "مجموعة جديدة",
        edit_group: "تحرير المجموعة",
        updating_preview: "تحديث المعاينة",
        no_preview_available: "لا تتوفر معاينة",
        select_update_preview: "حدد معاينة التحديث",
        error_updating_preview: "خطأ في تحديث المعاينة",
        no_groups: "لا توجد مجموعات متوفرة في النظام",
        no_groups_with_label: "لا توجد مجموعات ذات تسمية محددة",
        group_del_confirmation: "هل ترغب في حذف المجوعة(اسم المجموعة )؟",
    },

    labels: {
        label_name: "اسم التسمية",
        name_required: "اسم التسمية مطلوب",
        error_saving_label: "خطأ في حفظ التسمية",
        error_updating_label: "خطأ في تحديث التسمية",
        no_labels: "تسميات غير محددة",
        alerts_enabled: "تم تمكين التنبيهات",
        label_del_confirmation: "هل ترغب في حذف التسمية(اسم التسمية)؟",
    },

    login: {
        email: "البريد الالكترونى",
        new_password: "كلمة مرور جديدة",
        invalid_email: "بريد إلكتروني غير صالح",
        password_too_short: "يجب أن تحتوي كلمة المرور على 8 أحرف على الأقل",
        password_no_valid: "يجب أن تحتوي كلمة المرور على 8 أحرف أبجدية رقمية على الأقل برقم واحد وحرف كبير وحرف صغير",
        password_updated: "تم تحديث كلمة المرور",
        error_updating_password: "خطأ في تحديث كلمة المرور",
        send: "إرسال ",
        review_inbox: "تحقق من صندوق البريد الإلكتروني الخاص بك",
        error_sending_email: "خطأ في إرسال البريد الإلكتروني",
        password: "كلمة المرور",
        invalid_credentials: "بيانات اعتماد غير صالحة",
        forgot_password: "هل نسيت كلمة المرور الخاصة بك",
        enter: "أدخل",
        entering_our_web: "بدخولك إلى هذا الموقع ، فإنك تقبل موقعنا",
        terms_of_service: "شروط الخدمة",
        welcome: "أهلا بك",
        access_client:"وصول المشترك",
        two_factor_activated: "تنشيط عاملين",
        error_activating_2fa: "خطأ في تنشيط المصادقة الثنائية",
        code_2fa: "تم إرسال الرمز إلى بريدك الإلكتروني",
        invalid_code2fa: "رمز ٢فا غير صالح",
        remember_me: "تذكرني على هذا الجهاز لمدة 30 يوما",

    },

    menus: {
        close_session: "إغلاق الحساب",
        my_account: "حسابي",
        users: "المستخدمون",
        change_password: "تغيير كلمة المرور",
        two_factor: "تكوين ٢فا",
        viewer_config:"تكوين العارض",
        settings: "الاعدادات",
        user_manual: "دليل المستخدم",
        all: "الكل",
        exit_client: "العودة",

    },

    notifications: {
        no_notifications: "لا إشعارات",
        type: "النوع",
        info: "معلومات",
        mark_all_read: "وضع علامة على الكل كمقروء",
        delete_all: "حذف الكل",
        notifications_enabled: "تم تمكين الإشعارات",
        notifications_disabled: "الإشعارات معطلة",
        connection_lost: "فقد الإتصال",
        connection_recovered: "تم استرداد الإتصال",
        motion_detected: "تم اكتشاف الحركة",
        audio_detected: "تم اكتشاف الصوت",
        live_popup: "تم استلام الحدث",
        unknown: "غير معروف",

    },

    recordings: {
        export: "تصدير",
        lock: "قفل",
        unlock: "فتح",
        type: "النوع",
        camera: "كاميرا",
        select: "حدد",
        no_recordings: "لا توجد تسجيلات متوفرة في النظام",
        rec_del_confirmation: "هل ترغب في حذف تسجيل الكاميرا (اسم الكاميرا)؟",
        no_recs_for_date: "لا توجد تسجيلات متاحة للتاريخ المحدد",
    },

    tasks: {
        timetable: "الجدول الزمني",
        task_name: "اسم المهمة",
        type: "النوع",
        continuous: "التسجيل المستمر",
        motion_detection: "كشف الحركة",
        motion_notification: "اشعار الحركة",
        audio_notification: "اشعار صوتي",
        day: "اليوم",
        add: "إضافة",
        unassigned: "غير معين",
        all: "الكل",
        name_required: "اسم المهمة مطلوب",
        at_least_one_timetable: "يجب عليك تحديد جدول زمني واحد على الأقل",
        at_least_one_camera: "يجب عليك اختيار كاميرا واحدة على الأقل",
        error_saving_task: "خطأ في حفظ المهمة",
        error_updating_task: "خطأ في تحديث المهمة",
        start_later_than_end: "وقت البدء متأخر عن وقت الانتهاء",
        overlap_in_start_time: "التداخل في وقت البدء لـ (يوم من أيام الأسبوع)",
        overlap_in_end_time: "التداخل في وقت الانتهاء لـ (يوم من أيام الأسبوع)",
        no_tasks: "لا توجد مهام تم تكوينها في النظام",
        task_del_confirmation: "هل ترغب في حذف المهمة (اسم المهمة)؟",

    },

    users: {
        current_password: "كلمة المرور الحالية",
        new_password: "كلمة مرور جديدة",
        password_incorrect: "كلمة المرور الحالية غير صحيحة",
        password_too_short: "يجب ان تحتوي كلمة المرور على ٨ أحرف على الأقل",
        password_no_valid: "يجب أن تحتوي كلمة المرور على 8 أحرف أبجدية رقمية على الأقل برقم واحد وحرف كبير وحرف صغير",
        error_updating_password: "خطأ في تحديث كلمة المرور",
        privileges: "الامتيازات",
        first_name: "الاسم الاول",
        user_first_name: "الاسم الاول للمستخدم",
        surname: "اللقب",
        user_surname: "لقب المستخدم",
        email: "البريد الإلكتروني",
        user_email: "البريد الإلكتروني الخاص بالمستخدم",
        password: "كلمة المرور",
        user_password: "كلمة مرور المستخدم",
        first_name_required: "الاسم الاول مطلوب",
        surname_required: "اللقب مطلوب",
        email_required: "البريد الالكتروني مطلوب",
        email_format_invalid: "تنسيق بريد إلكتروني غير صالح",
        error_saving_user: "خطأ في حفظ المستخدم",
        error_updating_user: "خطأ في تحديث المستخدم",
        no_users: "لم يتم تكوين مستخدمين في النظام",
        user_del_confirmation: "هل ترغب في حذف المستخدم(بريد المستخدم)؟",
        error_updating_2fa: "خطأ في تحديث ٢ فا",
        email_sent: "تم إرسال بريد إلكتروني إلى حسابك لتفعيل المصادقة المزدوجة",
        error_user_email: "ليس من الممكن تنشيط المصادقة المزدوجة ، البريد الإلكتروني غير صالح",

    },

    privileges: {
        view_cameras: "عرض الكاميرات",
        ptz: "ب ت ز",
        ondemand_recording: "تسجيل عند الطلب",
        view_recordings: "عرض التسجيلات",
        export_recordings: "تصدير التسجيلات",
        delete_recordings: "حذف التسجيلات",
        edit_cameras: "تحرير الكاميرات",
        edit_tasks: "تحرير المهام",
        edit_groups: "تحرير المجموعات",
    },

    languages: {
        es: "Español",
        en: "English",
        ar: "عربي",
    },

    settings: {
        language: "اللغة",
        notifications: "الاخطارات",
        error_updating_language: "خطأ في تحديث اللغة",
        motion_detection: "كشف الحركة",
        audio_detection: "الكشف الصوتي",
        connectivity_events: "أحداث الاتصال",
        label_configuration: "تكوين التسمية",
        external_events: "الأحداث الخارجية",
        cameras: "كاميرات",
        order_by: "الطلب حسب",
        order_by_name: "الاسم",
        order_by_id: "هوية",
        two_factor_email: "المصادقة الثنائية عن طريق البريد الإلكتروني",
        use_viewer: "استخدام العارض",
        viewer_web: "الويب",
        viewer_app: "طلب",
        app_required:"تثبيت التطبيق",
        app_name: "IPNVideoPlayerSetup"
    }

};

export default ar;