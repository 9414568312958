import { createI18n } from 'vue-i18n'
import es from "@/locales/es";
import en from "@/locales/en";
import ar from "@/locales/ar";

const i18n = createI18n({
    locale: 'es', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages: { en, es , ar}, // set locale messages
})

export default i18n