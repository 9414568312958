<template>
  <div id="login">
    <div class="contenedor_login">
      <!--<img src="../../../public/img/logo.png" alt="" />-->
      <div id="logo" />
      <form>
        <div>
          <!--<i class="fa fa-user" aria-hidden="true"></i>-->
          <i class="material-icons" aria-hidden="true">person</i>
          <input
            type="email"
            id="email"
            disabled
            v-model="email"
            :placeholder="$t('login.email')"
            v-on:keyup.enter="login"
          />
        </div>
        <div>
          <i class="material-icons" aria-hidden="true">key</i>
          <input
            :type="passwordFieldType"
            v-model="password"
            :placeholder="$t('login.new_password')"
            v-on:keyup.enter="login"
          />
          <i class="material-icons"
            
            style="position: absolute; top: 8px; right: 12px; cursor: pointer"
            @click="switchPasswordVisibility()"
          >{{ this.passwordVisibility?"visibility":"visibility_off" }}</i>
          
        </div>
        <p class="login_msg" v-if="message != ''">{{ message }}</p>
        <button class="login_btn" type="button" @click.prevent="save">{{ $t('common.save') }}</button>
      </form>
      <div class="copy_login">
        <p>{{ $t('common.copyright', {year: new Date().getFullYear()}) }} | {{ $t('common.all_rights_reserved') }}</p>
      </div>
    </div>
  </div>
</template>


<script>
const axios = require("axios").default;
axios.defaults.withCredentials = false;
const Swal = require("sweetalert2");

export default {
  name: "PasswordUpdate",

  data() {
    return {
      message: "",
      token: "",
      email: "",
      tokenEmail: "",
      password: "",
      passwordVisibility: false,
    };
  },
  computed: {
    passwordFieldType() {
      return this.passwordVisibility ? "text" : "password";
    },
  },
  methods: {
    validateData() {
      if (this.email != this.tokenEmail) {
        this.message = this.$t('login.invalid_email');
        return false;
      }
      //const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;
      const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()-_=+{};:'",.<>?_`|~]{8,}$/;

      if (!regex.test(this.password)) {
        this.message = this.$t('login.password_no_valid');
         //this.message = this.$t('login.invalid_email');
        //this.message = "Probando";
        return false;
      }

      return true;
    },
    switchPasswordVisibility() {
      console.log("switchPasswordVisibility");
      this.passwordVisibility = !this.passwordVisibility;
    },
    save() {
      console.log("save");
      this.message = "";
      if (this.validateData()) {
        console.log("update password");
        this.updatePassword();
      }
    },
    async getEmail($token) {
      console.log("validateToken:", $token);
      const url = this.APIUrl + "/validate/" + $token;
      try {
        const response = await axios.get(url);
        console.log(response.status);
        if (response.status == 200 && response.data.result == 0) {
          this.email = response.data.email;
          this.tokenEmail = response.data.email;
          console.log("validateToken OK:", this.email);
        } else {
          console.log("validateToken error");
        }
      } catch (error) {
        console.log("validateToken error");
        console.error(error);
      }
    },
    async updatePassword() {
      this.message = "";
      console.log("updatePassword");
      const data = {
        email: this.email,
        password: this.password,
      };
      console.log("data", data);
      console.log("updatePassword:", this.token);
      const url = this.APIUrl + "/password/" + this.token;
      try {
        const response = await axios.put(url, data);
        console.log(response.status);
        console.log(response.data);
        if (response.status == 200 && response.data.result == 0) {
          this.message = "";
          Swal.fire({
            text: this.$t('login.password_updated'),
            icon: "success",
            confirmButtonText: this.$t('common.ok'),
            position: "center",
          }).then((result) => {
            console.log(result);
            if (result.isConfirmed) {
              this.$router.push({ name: "SignIn" });
            }
          });
        } else {
          console.log("updatePassword error");
          this.message = "";
          Swal.fire({
            text: this.$t('login.error_updating_password'),
            icon: "error",
            confirmButtonText: this.$t('common.ok'),
            position: "center",
          }).then((result) => {
            console.log(result);
            if (result.isConfirmed) {
              console.error("Error al actualizar contraseña");
            }
          });
        }
      } catch (error) {
        this.message = "";
        Swal.fire({
          text: this.$t('login.error_updating_password'),
          icon: "error",
          confirmButtonText: this.$t('common.ok'),
          position: "center",
        }).then((result) => {
          console.log(result);
          if (result.isConfirmed) {
            console.error("Error al actualizar contraseña");
          }
        });
      }
    },
  },
  mounted() {
    console.log("PasswordUpdate");
    this.token = this.$route.query.q;
    console.log("token:", this.token);
    this.getEmail(this.token);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import "../../assets/custom/css/login.css";
</style>