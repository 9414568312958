<template>
  <div class="cam_vista_min" v-on:click="viewCamera(id, name)">
    <div class="cam_preview">
      <img v-if="preview.textOrImage == 2" :src="preview.previewImg" alt="" />
      <span class="camid" v-if="showMasVideoId">{{ id + 1 }}</span>
      <span v-show="connStatus == 1" class="material-icons">cloud_done</span>
      <span v-show="connStatus == 0" class="material-icons">cloud_off</span>
      <span
        class="preview_msg"
        v-show="preview.textOrImage == 1"
        v-html="preview.previewText"
      ></span>
    </div>
    <div class="cam_menu">
      <div class="txt_cam">
        <h3 class="text-truncate">{{ name }}</h3>
        <p class="text-truncate">{{ model }}</p>
      </div>
      <div class="m_dot">
        <!-- Default dropstart button -->
        <div class="btn-group dropstart">
          <button
            v-on:click.stop
            type="button"
            class="btn btn-no-border"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            ref="dropdownbtn"
          >
            <span
              ><svg
                id="Modo_de_aislamiento"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1.78 10.75"
              >
                <circle
                  class="cls-1"
                  cx=".89"
                  cy="9.86"
                  r=".89"
                  transform="translate(-2.23 .46) rotate(-13.2)"
                />
                <circle
                  class="cls-1"
                  cx=".89"
                  cy="5.37"
                  r=".89"
                  transform="translate(-1.2 .35) rotate(-13.2)"
                />
                <circle
                  class="cls-1"
                  cx=".89"
                  cy=".89"
                  r=".89"
                  transform="translate(-.18 .23) rotate(-13.2)"
                /></svg
            ></span>
          </button>
          <ul class="dropdown-menu list-dropdown">
            <li v-if="wifi">
              <a
                @click.stop="$emit('editWifi', id, index)"
                style="white-space: nowrap"
                ><i class="fa fa-wifi" aria-hidden="true"></i>
                {{ $t("cameras.setup_wifi") }}</a
              >
            </li>
            <li v-if="deleteCamerasPriv">
              <a @click.stop="$emit('deleteEvent', id, index)"
                ><i class="fa fa-trash" aria-hidden="true"></i>
                {{ $t("common.delete") }}</a
              >
            </li>
            <li>
              <a @click.stop="updatePreview(id)"
                ><i class="fa fa-refresh" aria-hidden="true"></i>
                {{ $t("common.update_preview") }}</a
              >
            </li>
            <li v-if="editCamerasPriv">
              <a @click.stop="$emit('editEvent', id, index)"
                ><i class="fa fa-pencil" aria-hidden="true"></i>
                {{ $t("common.edit") }}</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CameraItem",
  //expose:['changeConnStatus'],
  props: {
    index: Number,
    id: Number,
    connStatus: Number,
    name: {
      type: String,
      required: true,
      default: "",
    },
    model: {
      type: String,
      default: "",
    },
    wifi: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["deleteEvent", "editEvent", "editWifi"],
  data() {
    return {
      /*previewURL: "/img/default_preview.jpg",*/
      //previewURL: "",
      loaded: false,
      error: false,
      viewCamerasPriv: false,
      editCamerasPriv: false,
      deleteCamerasPriv: false,
      previewText: "",
      previewUpdating: this.$t("cameras.updating_preview"),
      //previewTextOrImage: 0,
      //currentConnStatus:-1,
    };
  },
  computed: {
    preview() {
      //console.log("compute preview");
      let pr = {};
      pr.textOrImage = 0;
      pr.previewText = "";
      pr.previewImg = "";
      if (this.previewText != "") {
        pr.textOrImage = 1;
        pr.previewText = this.previewText;
        pr.previewImg = "";
        return pr;
      }
      if (this.$root.dataManager.camPreviews["cam" + this.id] === undefined) {
        pr.previewText = "";
        pr.previewImg = "pending";
        pr.textOrImage = 0;
        return pr;
      }
      if (
        this.$root.dataManager.camPreviews["cam" + this.id] === "downloading"
      ) {
        pr.previewText = "";
        pr.previewImg = "";
        pr.textOrImage = 0;
        return pr;
      }
      if (
        this.$root.dataManager.camPreviews["cam" + this.id] === "unavailable"
      ) {
        pr.previewText =
          this.$t("cameras.no_preview_available") +
          "<br>" +
          this.$t("cameras.select_update_preview");
        pr.textOrImage = 1;
        return pr;
      }
      pr.previewText = "";
      pr.previewImg = this.$root.dataManager.camPreviews["cam" + this.id];
      pr.textOrImage = 2;
      return pr;
    },
    showMasVideoId() {
      let show = getComputedStyle(document.documentElement).getPropertyValue(
        "--show-masvideo-id"
      );
      console.log("Show", show);
      if (show == 1) return true;
      else return false;
    },
  },
  watch: {
    preview() {
      if (this.preview.previewImg == "pending") {
        this.getPreview(this.id);
      }
    },
  },
  created() {
    //console.log("CameraItem created");
    this.getPreview(this.id);
  },
  beforeMount() {
    //console.log("CameraItem beforeMounted");
  },
  mounted() {
    //console.log("CameraItem mounted");
    //this.currentConnStatus = this.connStatus;
    const userPriv = this.$root.sessionManager.privileges;
    //console.log("UsersMain, userPriv:", userPriv);
    this.viewCamerasPriv = userPriv["viewCameras"];
    this.editCamerasPriv = userPriv["editCameras"];
    this.deleteCamerasPriv = userPriv["deleteCameras"];
    this.$refs.dropdownbtn.addEventListener("show.bs.dropdown", function () {
      var oldMenu = document.querySelector(".dropdown-menu.show");
      if (oldMenu != null) oldMenu.classList.remove("show");
    });
  },
  methods: {
    openCentered(url, parent, width, height) {
      const y = parent.top.outerHeight / 2 + parent.top.screenY - height / 2;
      const x = parent.top.outerWidth / 2 + parent.top.screenX - width / 2;
      var w = window.open(
        url,
        "",
        "height=" +
          height +
          ",width=" +
          width +
          ",status=yes,toolbar=no,menubar=no,location=no,scrollbars=0,resizable=1,top=" +
          y +
          ", left=" +
          x
      );
      w.desiredWidth = width;
      w.desiredHeight = height;
      return w;
    },
    viewCamera(id, name) {
      //alert("CamId:" + id);
      let viewer = localStorage.getItem("Viewer");
      let url = "view_camera.html?camid=" + id + "&camname=" + name;
      if (viewer == null || viewer == "web") {
        console.log("viewCam camid:", id, name);
        var newWindow = this.openCentered(url, window, 480, 270);
        newWindow.parent = window;
      } else {
        var buffer = require('buffer/').Buffer;
        const userPriv = JSON.parse(sessionStorage.getItem("sessionManager.privileges"));
        var params = "dispatcher=" + sessionStorage.getItem("sessionManager.dispatcher") + "\r\n";
        params += "sessionid=" + sessionStorage.getItem("sessionManager.sessionId") + "\r\n";
        params += "action=0"  + "\r\n";
        params += "id=" + id + "\r\n";
        params += "setupfile="  + this.APIServer + "/files/"  + this.$t("settings.app_name") + ".exe"  + "\r\n";
        params += "reqversion="  + "8.0.0.0"  + "\r\n";
        params += "language="  + sessionStorage.getItem("sessionManager.language")  + "\r\n";
        params += "ondemandrec="  + (userPriv['onDemandRec']?"1":"0")  + "\r\n";
        params += "hotcolor=#55C1FF"  + "\r\n";
        params += "mdmarkscolor=#FF0000"  + "\r\n";
        console.log("params:")
        console.log(params);
        const url = "ipnvideo:" + buffer.from(params).toString('base64')
        console.log("location:",url)
        window.location.href = url

      }

      //console.log(newWindow);
    },
    async updatePreview(id) {
      document.querySelector(".dropdown-menu.show").classList.remove("show");
      //const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      //console.log("updatePreview:", userInfo.sessionId);
      //sessionStorage.removeItem("previewCam" + id + ":" + userInfo.sessionId);
      this.$root.dataManager.deleteCameraPreview(id);
      //this.previewURL="";
      this.previewText = this.previewUpdating + "<br>&nbsp;";
      //await sleep(1200);
      let times = 0;
      let self = this;
      let interval = setInterval(function () {
        times++;
        if (times < 20) {
          self.previewText = self.previewUpdating + "<br>" + ".".repeat(times);
        }
      }, 1000);
      try {
        //this.previewText = this.previewUpdating + "<br>.";
        await this.$root.dataManager.updateCameraPreview(id);
        //this.previewText = this.previewUpdating + "<br>..";
        console.log("updatePreview OK");
        //await sleep(1000);
        //this.previewText = this.previewUpdating + "<br>...";
        clearInterval(interval);
        this.previewText = "";
        //this.getPreview(id);
      } catch (error) {
        clearInterval(interval);
        console.log("error updating preview");
        this.previewText = this.$t("cameras.error_updating_preview");
      }
    },
    async getPreview(id) {
      try {
        await this.$root.dataManager.getCameraPreviewBase64(id);
        //this.previewText="";
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*important top:-155px to see complete menu in large list of cameras*/
.dropstart .dropdown-menu.show {
  top: -155px !important;
  transform: translate(-30px, 0) !important;
}
</style>
