<template>
  <menu-main></menu-main>
  <div id="ViewerConfig">
    <header-main> </header-main>
    <div class="cuerpo_principal">
      <div id="confUser" class="container">
        <div class="row">
          <div
            class="col-12 offset-0 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-md-8 offset-md-2 col-lg-6 offset-lg-3 visor_box"
          >
            <ul class="nav nav-tabs nav-fill" role="tablist"></ul>
            <button
              @click="closeWindow"
              type="button"
              class="close_props material-icons"
              style="float: right"
              aria-label="Close"
            >
              close
            </button>
            <div class="tab-content" id="npContenido" style="border: 0px">
              <div
                class="tab-pane fade show active"
                id="informacion"
                role="tabpanel"
                aria-labelledby="informacion-tab"
              >
                <div class="conf_box">
                  <div class="nom_input">
                    <p>{{ $t("settings.use_viewer") }}</p>
                    <div class="conf_tipo">
                      <select
                        class="dropdown_select"
                        v-model="viewer"
                        @change="onChange()"
                        id="viewer_elect"
                        name="viewer_elect"
                      >
                        <option value="web">
                          {{ $t("settings.viewer_web") }}
                        </option>
                        <option value="app">
                          {{ $t("settings.viewer_app") }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div v-show="showLink" class="msg">
                  <p>{{ $t("settings.app_required") }}</p>
                  <a
                    class="nav-link"
                    :href="'/files/' + this.$t('settings.app_name') + '.exe'"
                    target="_blank"
                    ><i class="material-icons" aria-hidden="true">extension</i
                    >{{ $t("settings.app_name") }}</a
                  >
                </div>
              </div>
            </div>

            <div class="btn_guardar">
              <button @click.prevent="save">
                <a href="#">{{ $t("common.save") }}</a>
                <div
                  v-if="saving"
                  class="spinner-border spinner-border-sm"
                  role="status"
                >
                  <span class="sr-only">Saving...</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <menu-footer active-state=""></menu-footer>
</template>

<script>
export default {
  name: "ViewerConfig",
  data() {
    return {
      error: false,
      saving: false,
      viewer: "web",
      path: "",
      showLink: false,
    };
  },
  methods: {
    isWindowsClient() {
      let os = navigator.userAgent;
      console.log("userAgent:", os);
      if (os.search("Windows") !== -1) {
        return true;
      }
      return false;
    },
    closeWindow() {
      console.log("closeWindow");
      console.log(this.$router);
      this.$router.go(-1);
    },
    showMessage() {
      if (this.viewer == "app") {
        this.showLink = true;
      } else {
        this.showLink = false;
      }
    },
    onChange() {
      this.showMessage();
    },
    save() {
      this.error = false;
      console.log("save:", this.viewer);
      localStorage.setItem("Viewer", this.viewer);
      this.$router.go(-1);
    },
  },
  mounted() {
    console.log("ViewerConfig");
    let viewer = localStorage.getItem("Viewer");
    if (viewer == null) {
      console.log("viewer not configured");
      this.viewer = "web";
    } else {
      this.viewer = viewer;
      console.log("viewer configured:", this.viewer);
    }
    if (!this.$root.sessionManager.isLoggedIn()) {
      console.log("router to signin");
      this.$router.push({ name: "SignIn" });
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container .nav-tabs {
  border-bottom: none !important;
}
#npContenido {
  padding-top: 65px;
}

.nom_input > div {
  position: relative;
}
.nom_input > div i {
  padding-left: 8px;
  font-size: 20px;
  line-height: 1;
  vertical-align: top;
  padding-top: 2px;
}
.sw_prog .sw_contenedor p {
  width: 320px;
  text-align: left;
}
</style>
